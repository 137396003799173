<template>
	<div class="maxBox">
		<div class="loginBox">
			<h1>登录</h1>
			<el-form
				:label-position="labelPosition"
				label-width="100px"
				:model="form"
				style="width: 446px"
			  >
				<el-form-item label="账号">
				  <el-input v-model="form.username" />
				</el-form-item>
				<el-form-item label="密码">
				  <el-input type="password" v-model="form.password" />
				</el-form-item>
				<el-form-item label="验证码" v-if="captchaOnOff">
				  <el-input v-model="form.code" />
				  <div class="login-code">
				    <img :src="codeUrl" @click="getCode" class="login-code-img"/>
				  </div>
				</el-form-item>
				<el-form-item>
				  <el-button style="width: 100%;height: 47px;" 
					  type="primary" 
					  @click="submitForm"
				  >登录
				  </el-button>
				</el-form-item>
				<el-form-item>
				  <el-link type="info" href="/account/register">注册账号</el-link>
				</el-form-item>
			  </el-form>
			  <!-- <el-divider><span style="color: #CCCCCC;">第三方登录</span></el-divider>
			  <div class="otherLogin">
				  <a @click="toGoogle">
					  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16">
					    <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
					  </svg>
					  <span>google登录</span>
				  </a>
				  <a>
					  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
					    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
					  </svg>
					  <span>facebook登录</span>
				  </a>
			  </div> -->
		  </div>
	</div>
</template>

<script setup>
	import { googleSdkLoaded,googleTokenLogin } from "vue3-google-login";
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { reactive, ref, onMounted } from 'vue'
	import { useRouter } from 'vue-router'
	import { login,googleLogin,getCodeImg } from '@/api/user'
	import useUserStore from '@/store/modules/user'
	
	let router = useRouter();
	const labelPosition = ref('top')
	const form = reactive({
	  username: '',
	  password: '',
	  code: '',
	  uuid: ''
	})
	onMounted(()=>{
		getCode()
	})
	const captchaOnOff = ref(null)
	const codeUrl = ref('')
	const getCode = () => {
        getCodeImg().then(res => {
          captchaOnOff.value = res.captchaOnOff === undefined ? true : res.captchaOnOff;
          if (captchaOnOff.value) {
            codeUrl.value = "data:image/gif;base64," + res.img;
            form.uuid = res.uuid;
          }
        });
	}
	// 谷歌登录
	const googleId = ref('24965692980-pshgpks6sdnfbc8gr6gc3ffs3o8kvv30.apps.googleusercontent.com')
	const toGoogle = () => {
		// googleLogin({
		// 	clientId: googleId.value,
		// 	identityToken: 'ya29.a0AWY7CkkEtIjItLRUxluT920SAjTqSdEYKgWR-VRy925xYbglhlLJzWqz7JUfO7dZsvW5wQ_oO1C9THPvdHnvFJwC1vRVTtNNzVarbJieKAY7G-sD7_QUypWBDA8tSuiTuKeR6RpRNSRcnfKFJj7q_90aphn4IQaCgYKAZ4SARMSFQG1tDrpmWsB6sxULwtEphT0pW1i0Q0165'
		// }).then((res) => {
		// 	console.log(res)
		// })
		googleTokenLogin().then((response) =>{
			console.log(response)
			googleLogin({
				clientId: googleId.value,
				identityToken: response.access_token
			}).then((res) => {
				useUserStore().setInfo(res.data).then((res) => {
					ElMessage({
					    message: 'Success',
					    type: 'success',
					})
					router.push('/index')
				})
			})
		})
	  // googleSdkLoaded((google) => {
	  //     google.accounts.id.initialize({
	  //       client_id: googleId.value,
	  //       callback: (response) => {
			// 	console.log(response)
			// 	googleLogin({
			// 		clientId: response.clientId,
			// 		identityToken: response.credential
			// 	}).then((res) => {
			// 		console.log(res)
			// 	})
	  //       }
	  //     })
		 //  google.accounts.id.prompt();
	  //   })
	};
	const submitForm = () => {
		useUserStore().login(form).then(()=>{
			useUserStore().getInfo().then(() => {
				ElMessage({
				    message: 'Success',
				    type: 'success',
				})
				router.push('/index')
			})
		})
		// login(form).then((res) => {
		// 	console.log(res)
		// })
	}
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
	.maxBox{flex-wrap: wrap;}
	h1{width: 100%;}
	.loginBox{
		max-width: 446px;margin: 0 auto;width: 100%;
		.login-code {
		  width: 115px;height: 43px;float: right;
		  img {
		    cursor: pointer;
		    vertical-align: middle;
		  }
		}
		.login-code-img {
		  height: 43px;
		}
	}
	.otherLogin{
		width: 100%;margin-top: 40px;display: flex;justify-content: space-between;
		a{
			display: inline-flex;align-items: center;justify-content: center;
			box-sizing: border-box;border-radius: 25px;
			width: 49%;padding: 12px 10px;color: rgba(18,18,18,0.75);
			border: 1px solid rgba(18,18,18,0.75);font-size: 15px;cursor: pointer;
			transition: 0.3s;
			span{
				margin-left: 12px;
			}
			&:hover{
				background: rgb(18,18,18);color: #fff;
			}
		}
	}
</style>
